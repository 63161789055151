import React from "react";
import "./Team.css";
import team from "./team.jpg";
import Footer from "./Footer";

const Team = () => {
  return (
    <>
      <div id="team-main">

        <div id="team-image-container">
          <img src={team} />
        </div>

        <div id="team-people-container">
          <div className="team-div">
            <h3>Tzvi Stern</h3>
            <p>Founder and CEO</p>
          </div>
          <div className="team-div">
            <h3>Dr. Feiner</h3>
            <p>Liscenced psychologist and advisor. </p>
          </div>
          <div className="team-div">
          <h3>Kalli Taub</h3>
          <p>Marketing Director</p>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Team;
