import React from "react";
import "./ContactSection.css";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

const ContactSection = () => {
  return (
    <>
      <div id="contact-main">
        <h1>Contact Us</h1>

        <div id="form-container">
          <form
            action="https://formsubmit.co/steve@stablty.com"
            method="POST"
          >
            <input type="hidden" name="_cc" value="Marketing@stablty.com"></input>
            <input type="hidden" name="_captcha" value="false"></input>
            <TextField
              rowsMax={Infinity}
              margin="normal"
              fullWidth
              type="text"
              name="name"
              required
              placeholder="name"
            ></TextField>
            <TextField
              fullWidth
              margin="normal"
              type="email"
              name="email"
              required
              placeholder="email"
            ></TextField>
            <TextField
              fullWidth
              margin="normal"
              type="textarea"
              name="message"
              required
              placeholder="your message"
              multiline
              inputProps={{style : {height : "150px", textAlign : "left"}}}
            ></TextField>
            <Button sx={{ mt: 3, backgroundColor: "#00308F" }} variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
