import React from "react";
import "./TextAndImageFull.css";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const TextAndImageFull = ({ image, header, text }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div id="full-main">
        <div id="full-image-main">
          <img src={image} data-aos="fade-up" />
        </div>

        <div id="full-text-main">
          <h1 data-aos="fade-left">{header}</h1>
          <p data-aos="fade-up">{text}</p>
          <Button sx={{ mt: 2 }} variant="contained" data-aos="fade-up">
            <NavLink className="demo-link" to="/contact">
              Request a Demo
            </NavLink>
          </Button>
        </div>
      </div>
    </>
  );
};

export default TextAndImageFull;
