import React from "react";
import Accordion from "./Accordion";
import "./Help.css";
import PositiveAttitude from "./PositiveAttitude";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import HelpMenu from "./HelpMenu";

const Help = () => {
  return (
    <>
      <PositiveAttitude />
    </>
  );
};

export default Help;
