import React from "react";
import "./Dashboard.css";

const Dashboard = () => {
 return (
    <>
      <div id="dashboard-container">
        <div id="dashboard-nav">
          {/* <div id="links">
            <a>Dashboard</a>
            <a>People</a>
            <a>Actions</a>
            <a>Reports</a>
            <a>Company</a>
          </div> */}
        </div>

        <div id="dashboard-top">
          <h3 id="top-header">Dashboard</h3>

          <div id="dashboard-top-containers">
            <div className="top-div"><p>Activating event trigger</p></div>
            <div className="top-div"><p>Thoughts</p></div>
            <div className="top-div"><p>Physical Sensations</p></div>
            <div className="top-div"><p>The Person I want to be</p></div>
            <div className="top-div"><p>What did I actually do?</p></div>
            <div className="top-div"><p>What happened afterwards?</p></div>
          </div>
        </div>

        <div id="dashboard-bottom">
          <div className="bottom-container first">
          </div>
          <div className="bottom-container"></div>
          <div className="bottom-container"></div>
          <div className="bottom-container"></div>
        </div>
      </div>
    </>
  )
};

export default Dashboard;
