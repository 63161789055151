import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Nav";
import Home from "./Components/Home";
import Help from "./Components/Help";
import Feeling from "./Components/Dashboard";
import Relationships from "./Components/Relationships";
import PositiveAttitude from "./Components/PositiveAttitude";
import StressManagement from "./Components/StressManagement";
import Coping from "./Components/Coping";
import Goals from "./Components/Goals";
import Gratitude from "./Components/Gratitude";
import Future from "./Components/Future";
import Therapists from "./Components/Therapists";
import NonProfits from "./Components/NonProfits";
import Schools from "./Components/Schools";
import Dashboard from "./Components/Dashboard";
import Healthcare from "./Components/Healthcare";
import Team from "./Components/Team";
import ContactSection from "./Components/ContactSection";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/help" element={<Help />} />
        <Route path="/emotions" element={<Feeling />} />
        <Route path="/goals" element={<Goals />} />
        <Route path="/stressmanagement" element={<StressManagement />} />
        <Route path="/relationships" element={<Relationships />} />
        <Route path="/positiveattitude" element={<PositiveAttitude />} />
        <Route path="/coping" element={<Coping />} />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/future" element={<Future />} />
        <Route path="/therapists" element={<Therapists />} />
        <Route path="/nonprofits" element={<NonProfits />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/healthcare" element={<Healthcare />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<ContactSection />} />
      </Routes>
    </>
  );
}

export default App;
