import React from "react";
import "./Therapists.css";
import therapy from "./therapist.jpg";
import TextAndImageFull from "./TextAndImageFull";
import Footer from "./Footer";

const Therapists = () => {
  return (
    <>
      <TextAndImageFull
        image={therapy}
        header={"We work with licensed therapists"}
        text={
          "We want to provide your clients with on demand mental healthcare and mindfullness resources. We recognize that therapy and other solutions work mainly in the moment, but here is an extra tool to provide to your clients outside of sessions"
        }
      />
      <Footer />
    </>
  );
};

export default Therapists;
