import React from "react";
import future from "./future.jpg"
import "./Future.css"

const Future = () => {
    return (
        <>
        <img id="future" src={future} />
        </>
    )
}

export default Future