import React from "react";
import { NavLink } from "react-router-dom";
import "./HelpMenu.css"

const HelpMenu = () => {
    return (
        <>
        <div id="help-menu-container">
        <div>
            <NavLink to="/PositiveAttitude">Positive Attitude</NavLink>
            <NavLink to="/Goals">Goals</NavLink>
            <NavLink to="/Gratitude">Gratitude</NavLink>
            <NavLink to="/Relationships">Relationships</NavLink>
            <NavLink to="/StressManagement">Stress Management</NavLink>
            <NavLink to="/Coping">Coping</NavLink>
        </div>
        </div>
        </>
    )
}

export default HelpMenu