import React from "react";
import "./Footer.css";
import linkedin from "./linkedin.png";
import logo from "./logo.jpg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div id="footer-container">
        <div id="footer-logo-container">
          <img src={logo} />
        </div>

        <div id="footer-links-container">
          <NavLink to="/help">Skills</NavLink>
          {/* <NavLink to="/therapists">Therapists</NavLink>
          <NavLink to="/healthcare">Healthcare</NavLink>
          <NavLink to="/schools">Schools</NavLink>
          <NavLink to="/nonprofits">Nonprofits</NavLink> */}
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>

        <div id="footer-social-container">
          <a href="https://www.linkedin.com/company/stablty/">
            <img id="logo" src={linkedin} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
