import React from "react";
import "./nonprofits.css";
import nonprofit from "./nonprofit.jpg";
import TextAndImageFull from "./TextAndImageFull";
import Footer from "./Footer";

const NonProfits = () => {
  return (
    <>
      <TextAndImageFull
        image={nonprofit}
        header={"We work with nonprofits."}
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vehicula eros et pharetra sollicitudin. Pellentesque vulputate, augue a rhoncus vehicula, sapien quam aliquet dolor, at mollis turpis est ut quam. Donec ullamcorper nisi ac tortor malesuada suscipit. Donec a porttitor metus. Aliquam a molestie turpis, sed pellentesque orci."
        }
      />
      <Footer />
    </>
  );
};

export default NonProfits;
