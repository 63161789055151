import React from "react";
import "./schools.css";
import schools from "./schools.jpg"
import TextAndImageFull from "./TextAndImageFull";
import Footer from "./Footer";

const Schools = () => {
  return (
    <>
      <TextAndImageFull image={schools} header={"We work with schools"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vehicula eros et pharetra sollicitudin. Pellentesque vulputate, augue a rhoncus vehicula, sapien quam aliquet dolor, at mollis turpis est ut quam. Donec ullamcorper nisi ac tortor malesuada suscipit. Donec a porttitor metus. Aliquam a molestie turpis, sed pellentesque orci."} />
      <Footer />
    </>
  );
};

export default Schools;
