import React from "react";
import CardsSection from "./CardsSection";
const OverviewSection = React.lazy(() => import("./OverviewSection"));
const Intuitive = React.lazy(() => import("./Intuitive"));
const Innovative = React.lazy(() => import("./Innovative"));
const Adaptive = React.lazy(() => import("./Adaptive"));
const Solution = React.lazy(() => import("./Solution"));
const Footer = React.lazy(() => import("./Footer"));
const Main = React.lazy(() => import("./Main"));
const ContactSection = React.lazy(() => import("./ContactSection"));
const AboutUs = React.lazy(() => import("./AboutUs"));
const CircleCharts = React.lazy(() => import("./CircleCharts"))


const Home = () => {
  return (
    <>
      <React.Suspense>
        <Main />
        <AboutUs />
        <CardsSection />
        <CircleCharts />
        <Solution />
        <ContactSection />
        <Footer />
      </React.Suspense>
    </>
  );
};

export default Home;
