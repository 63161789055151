// import * as React from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from "@mui/icons-material/Adb";
// import { NavLink } from "react-router-dom";
// import logo from "./logo.jpg"
// import "./Nav.css";

// const pages = ["Dashboard", "Emotions", "Future", "Therapists", "Healthcare", "Schools", "NonProfits"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

// const ResponsiveAppBar = () => {
//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   return (
//     <AppBar position="static" id="navbar">
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: "none", md: "flex" },
//               fontFamily: "monospace",
//               fontWeight: 700,
//               letterSpacing: ".3rem",
//               color: "inherit",
//               textDecoration: "none",
//             }}
//           >
//           </Typography>

//           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon id="menu-icon" />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "left",
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: "block", md: "none" },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center">
//                     <NavLink to={`/${page}`}>{page}</NavLink>
//                   </Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>

//           {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
//           <NavLink to="/"><img id="logo" src={logo} /></NavLink>
//           <Typography
//             variant="h5"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: "flex", md: "none" },
//               flexGrow: 1,
//               fontFamily: "monospace",
//               fontWeight: 700,
//               letterSpacing: ".3rem",
//               color: "inherit",
//               textDecoration: "none",
//             }}
//           >
//           </Typography>
//           <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
//             {pages.map((page) => (
//               <Button
//                 key={page}
//                 onClick={handleCloseNavMenu}
//                 sx={{ my: 2, color: "white", display: "block" }}
//               >
//                 <NavLink to={`/${page}`}>{page}</NavLink>
//               </Button>
//             ))}
//           </Box>

//           <Box sx={{ flexGrow: 0 }}>
//             <Tooltip title="Open settings">
//               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//                 {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
//               </IconButton>
//             </Tooltip>
//             <Menu
//               sx={{ mt: "45px" }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem key={setting} onClick={handleCloseUserMenu}>
//                   <Typography textAlign="center">{setting}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// };
// export default ResponsiveAppBar;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "./logo.jpg";
import "./Nav.css";
import { useState } from "react";
import remove from "./remove.png";
import zIndex from "@mui/material/styles/zIndex";

const Navbar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [hidden, setHidden] = useState(true);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleHidden = () => {
    if (hidden !== true) {
      setHidden(!hidden);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleHidden);
    return () => window.removeEventListener("click", handleHidden);
  });

  const isMobile = width < 801;

  if (isMobile) {
    return (
      <>
        <div id="navbar">
          <NavLink to="/">
            <img id="nav-img" src={logo} />
          </NavLink>

          {/* <div className="skills-container"> */}

          {/* <div
              className={`${hidden === true ? "hidden" : "skills-dropdown"}`}
            >
              <a>Mindfulness</a>
              <a>Sleep</a>
              <a>Nutrition</a>
              <a>Technology & the brain</a>
              <a>Physical Exercise</a>
              <a>Social Connection</a>
              <a>Gratitude</a>
              <a>Managing Stress</a>
              <a>Anger</a>
              <a>Anxiety</a>
              <a>Values</a>
              <a>Changing Habits</a> */}
          {/* 
              <button onClick={() => setHidden(!hidden)}>
                <img className="close-btn" src={remove} />
              </button> */}
          {/* </div>
          </div> */}

          {/* <NavLink to="#" onClick={() => setHidden((hidden) => !hidden)}>
            Skills
          </NavLink> */}

          <NavLink to="/therapists">Therapists</NavLink>
          <NavLink to="/healthcare">Healthcare</NavLink>
          <NavLink to="/schools">Schools</NavLink>
          <NavLink to="/nonprofits">Nonprofits</NavLink>
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="navbar">
          <NavLink to="/">
            <img id="nav-img" src={logo} />
          </NavLink>

          {/* <div className="skills-container">
            <NavLink onClick={() => setHidden((hidden) => !hidden)} to="#">
              Skills
            </NavLink>
            <div
              className={`${hidden === true ? "hidden" : "skills-dropdown"}`}
            >
              <a>Mindfulness</a>
              <a>Sleep</a>
              <a>Nutrition</a>
              <a>Technology & the brain</a>
              <a>Physical Exercise</a>
              <a>Social Connection</a>
              <a>Gratitude</a>
              <a>Managing Stress</a>
              <a>Anger</a>
              <a>Anxiety</a>
              <a>Values</a>
              <a>Changing Habits</a>
              {/* <button onClick={() => setHidden(!hidden)}>
                <img className="close-btn" src={remove} />
              </button> */}
          {/* </div>
          </div> */}
{/* 
          <NavLink onClick={() => setHidden((hidden) => !hidden)} to="#">
            Skills
          </NavLink> */}
{/* 
          <NavLink to="/dashboard">Dashboard</NavLink> */}
          <NavLink to="/therapists">Therapists</NavLink>
          <NavLink to="/healthcare">Healthcare</NavLink>
          <NavLink to="/schools">Schools</NavLink>
          <NavLink to="/nonprofits">Nonprofits</NavLink>
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </>
    );
  }
};

export default Navbar;
