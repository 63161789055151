
import HelpMenu from "./HelpMenu";
import positive from "./positivity.jpg";
import ImageSLider from "./ImageSlider";
import HappyBalloons from "./happyballoons.jpg";

import React from "react";
import { Card } from "@mui/material";
import "./PositiveAttitude.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const slides = [{ url: positive }, { url: HappyBalloons }];
const PositiveAttitude = () => {
  return (
    <>
      <div id="positive">
        <HelpMenu />
        <h1 id="positive-header">Positive Attitude</h1>

        {/* <div id="positive-slider-container">
          <ImageSLider slides={slides} />
        </div> */}

        <div id="positive-text-container">
          <div id="positive-benefits-div">
            <h3 className="positive-list-header">
              Benefits of a positive attitude
            </h3>
            <ul>
              <li>Increased life span</li>
              <li>Lower Rates of Depression</li>
              <li>Lower levels of distress and pain.</li>
              <li>Greater resistance to illness</li>
            </ul>
          </div>

          <div id="positive-benefits-div">
            <h3 className="positive-list-header">
              {" "}
              Tips to help keep a positive attitude
            </h3>
            <ul>
              <li>Appreciate what you have.</li>
              <li>Do not compare yourself to others</li>
              <li>Have gratitude towards your family and friends</li>
            </ul>
          </div>

          <div id="positive-benefits-div">
            <h3 className="positive-list-header">
              {" "}
              Tips to help keep a positive attitude
            </h3>
            <ul>
              <li>Appreciate what you have.</li>
              <li>Do not compare yourself to others</li>
              <li>Have gratitude towards your family and friends</li>
            </ul>
          </div>
        </div>

       <Link to="/goals">
         <Button sx={{ mt: 3 }} variant="contained">
            Next
          </Button>
        </Link>
      </div>     </>
  );
};

export default PositiveAttitude;
