import React from "react";
import "./Healthcare.css";
import healthcare from "./healthcare.jpg";
import TextAndImageFull from "./TextAndImageFull";
import Footer from "./Footer";

const Healthcare = () => {

  return (
    <>
      <TextAndImageFull
        image={healthcare}
        header={"We work with healthcare professionals"}
        text={
          "We understand the impact of high pressure and stressful jobs, and we want to provide ideas, concepts, resources, and skills to help deal with that."
        }
      />
      <Footer />
    </>
  );
};

export default Healthcare;
