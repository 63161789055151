import React from "react";
import "./CardsSection.css";

const CardsSection = () => {
  return (
    <>
      <div id="cards-section-main">
        <div className="cards-section-div">
          {/* <img className="card-image" src={brain} /> */}
          <p>
            <span>Stablty is engineered</span> intuitively so that users can
            spend brief yet meaningful sessions building up their mental
            capacities through guided content, journaling, and other unique
            tools that help them grow the strength of their mind.
          </p>
        </div>

        <div className="cards-section-div">
          {/* <img className="card-image" src={ai} /> */}
          <p>
            <span>Stablty is a solution</span> for people who don't connect with meditation. It offers an intuitive and comprehensive platform that engages users to learn and utilize healthy tools to help control their mind.{" "}
          </p>
        </div>

        <div className="cards-section-div">
          {/* <img className="card-image" src={puzzle} /> */}
          <p>
            <span>Stablty combines</span>the latest scientific research in mental health with effective techniques that users can quickly pick up and adapt to their lifestyle. It's a fitness app for mindfulness that helps users build their mental muscles.
          </p>
        </div>
        <div className="cards-section-div">
          {/* <img className="card-image" src={puzzle} /> */}
          <p>
            <span>Stablty offers</span>customization that analyzes user patterns, suggests targeted areas for improvement, and allows the user to choose their own learning paths.
          </p>
        </div>
      </div>
    </>
  );
};

export default CardsSection;
